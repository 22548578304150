import React, { Fragment } from "react";
import { Header, Overheader, GlobalStyles, Separator, LiveSessions, WorkHistory, Footer } from "../components";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <Fragment>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Organic Audio - Glasgow Recording Studio</title>
        <meta
          name="description"
          content="A cozy, and intimate recording studio settled in the south side of Glasgow. We run frequent live sessions with touring and local artists/bands as well as full on studio recordings."
        />
        <link rel="icon" href="assets/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Lora&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Damion&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap" rel="stylesheet" />
      </Helmet>
      <Overheader />
      <Separator />
      <Header />
      <Separator />
      <LiveSessions />
      <Separator />
      <WorkHistory />
      <Separator />
      <Footer />
    </Fragment>
  );
};

export default IndexPage;
